import React from 'react';

import { FormattedMessage } from 'react-intl';

import { Stack, Typography } from '@mui/material';

import { CameronQuote } from '../../pageComponents/start/CameronQuote';

import { InformationSection } from './InformationSection';

const returnStepper = [
  {
    heading: '100,000',
    description: 'Total properties analyzed',
  },
  {
    heading: '10%',
    description: 'Passed data screening',
  },
  {
    heading: '.5%',
    description: 'Met team standards',
  },
  {
    heading: '< 0.2%',
    description: 'Properties purchased',
  },
];
export const DiligenceProcess = () => {
  return (
    <InformationSection
      title={<FormattedMessage id="homepage-v2.diligence-process.title" />}
      dataE2e="homepage-v2.diligence-process"
    >
      <Stack
        direction={{
          xs: 'column',
          md: 'row',
        }}
        sx={({ palette }) => ({
          my: 3,
          border: `1px solid ${palette.neutrals.platinum}`,
          borderRadius: '10px',
        })}
        data-e2e="homepage-v2.property-stepper"
      >
        {returnStepper.map((item, idx, array) => {
          return (
            <Stack
              key={idx}
              sx={({ palette }) => ({
                position: 'relative',
                width: {
                  xs: '100%',
                  md: '25%',
                },
                backgroundColor:
                  idx === 0
                    ? palette.neutrals.white
                    : idx === 1
                      ? palette.newPrimary[0]
                      : idx === 2
                        ? palette.newPrimary[100]
                        : palette.newPrimary[200],
                borderRight: idx < array.length - 1 ? `1px solid ${palette.neutrals.platinum}` : 'none',
                pt: 2.8,
                pb: 3.2,
                px: 3.8,
                gap: {
                  md: 2,
                },
                '&:first-child': {
                  borderRadius: {
                    xs: '10px 10px 0 0',
                    md: '10px 0 0 10px',
                  },
                },
                '&:last-child': {
                  borderRadius: {
                    xs: '0 0 10px 10px',
                    md: '0 10px 10px 0',
                  },
                },
                '&::after':
                  idx < array.length - 1
                    ? {
                        content: '""',
                        position: 'absolute',
                        top: {
                          xs: '-8px',
                          md: '0',
                        },
                        bottom: {
                          md: '0',
                        },
                        margin: {
                          md: 'auto 0',
                        },
                        right: {
                          xs: '50%',
                          md: '-8px',
                        },
                        width: 16,
                        height: 16,
                        transform: 'rotate(45deg)',
                        backgroundColor:
                          idx === 0
                            ? palette.neutrals.white
                            : idx === 1
                              ? palette.newPrimary[0]
                              : palette.newPrimary[100],
                        borderBottom: {
                          xs: `1px solid ${palette.neutrals.platinum}`,
                          md: 'unset',
                        },
                        borderTop: {
                          md: `1px solid ${palette.neutrals.platinum}`,
                        },
                        borderRight: `1px solid ${palette.neutrals.platinum}`,
                        borderRadius: '2px',
                        zIndex: 2,
                      }
                    : {
                        content: 'unset',
                      },
              })}
            >
              <Typography variant="h4" fontWeight="medium">
                {item.heading}
              </Typography>
              <Typography color={({ palette }) => palette.darkened.steel}>{item.description}</Typography>
            </Stack>
          );
        })}
      </Stack>
      <CameronQuote />
    </InformationSection>
  );
};

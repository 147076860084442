import Image from 'next/image';

import { FormattedMessage, useIntl } from 'react-intl';

import { Box, Stack, StackProps, Typography } from '@mui/material';

import { tokens } from '@arrived/bricks';
import { Constants, getCDNOptimizedImage } from '@arrived/common';

import { EmailSignup } from '../../pageComponents/start/EmailSignup';

import { MarketingVideo } from './MarketingVideo';

const ARRIVED_PROPERTIES_MOBILE_IMAGE_URL = `${Constants.awsCdnUrl}/images/landing/houses-mobile.png`;
const ARRIVED_PROPERTIES_IMAGE_URL = `${Constants.awsCdnUrl}/images/landing/houses.png`;

export interface HeroProps extends StackProps {
  handleEmailOnSubmit: (position: string) => (email: string) => void;
}

export const Hero = ({ handleEmailOnSubmit, sx, ...rest }: HeroProps) => {
  const intl = useIntl();

  return (
    <Stack
      direction={{
        xs: 'column',
        lg: 'row',
      }}
      justifyContent={{
        xs: 'flex-start',
        lg: 'space-between',
      }}
      width="100%"
      height="auto"
      sx={{
        background: ({ gradients }) => gradients.newLight.horizontal,
        overflowX: 'hidden',
        ...sx,
      }}
      data-e2e="homepage-v2.hero"
      {...rest}
    >
      <Stack
        px={{
          xs: 3.5,
          lg: 8,
          xl: 17,
        }}
        py={{
          xs: 5,
          lg: 18,
        }}
        sx={{
          position: 'relative',
          flex: '1 1 50%',
        }}
      >
        <MarketingVideo />
        <Typography
          variant="h1.semibold"
          component="h1"
          lineHeight={{
            xs: '2rem',
            md: '3rem',
            lg: '3.5rem',
            xl: '4rem',
          }}
          fontSize={{
            xs: '2rem',
            md: '3rem',
            lg: '3.5rem',
            xl: '4rem',
          }}
          mt={2}
          data-e2e="left-content-title"
        >
          <FormattedMessage id="homepage-v2.hero.title" />
        </Typography>
        <Typography variant="h5" component="h2" mt={3}>
          <FormattedMessage id="homepage-v2.hero.tagline" />
        </Typography>
        <EmailSignup
          mt="$6"
          alignSelf="center"
          maxWidth={500}
          width="100%"
          $gtXs={{ alignSelf: 'flex-start' }}
          flexDirection="row"
          gap="$2"
          onSubmit={handleEmailOnSubmit('homepage-v2-hero')}
        >
          <EmailSignup.Input
            inputProps={{
              containerProps: { flex: 1 },
              placeholderTextColor: tokens.color['neutral.light.500'].val,
              placeholder: intl.formatMessage({ id: 'common.email.placeholder' }),
            }}
          />
          <EmailSignup.Button />
        </EmailSignup>
      </Stack>
      <Stack
        sx={{
          position: 'relative',
          flex: '1 1 50%',
          overflow: 'hidden',
        }}
      >
        <Box sx={{ display: { xs: 'block', md: 'none' }, width: '100%', height: '223px', position: 'relative' }}>
          <Image
            src={ARRIVED_PROPERTIES_MOBILE_IMAGE_URL}
            alt="Arrived properties picture"
            quality={100}
            placeholder="blur"
            blurDataURL={getCDNOptimizedImage({
              imgUrl: ARRIVED_PROPERTIES_MOBILE_IMAGE_URL,
              quality: 5,
              blur: 10,
            })}
            fill
            style={{
              objectFit: 'cover',
            }}
            priority
          />
        </Box>
        <Box
          sx={{ display: { xs: 'none', md: 'block' }, position: 'absolute', top: '35%', transform: 'translateY(-35%)' }}
        >
          <Image
            src={ARRIVED_PROPERTIES_IMAGE_URL}
            alt="Arrived properties picture"
            width={620}
            height={1021}
            quality={100}
            placeholder="blur"
            blurDataURL={getCDNOptimizedImage({ imgUrl: ARRIVED_PROPERTIES_IMAGE_URL, quality: 5, blur: 10 })}
            priority
          />
        </Box>
      </Stack>
    </Stack>
  );
};

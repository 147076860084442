export * from './Faqs';
export * from './Hero';
export * from './HeroBoxesLarge';
export * from './HeroBoxesSmall';
export * from './HowArrivedWorks';
export * from './InformationSection';
export * from './InThePress';
export * from './PropertyCards';
export * from './DiligenceProcess';
export * from './ReturnCard';
export * from './ReturnCards';
export * from './TopBenefits';
export * from './ValueProps';

import { ReactNode } from 'react';

import { FormattedMessage, FormattedNumber } from 'react-intl';

import { Stack, StackProps, Typography } from '@mui/material';

import { FEATURE_FLAGS, useIsFeatureFlagEnabled } from '@arrived/feature-flags';
import { CompanyKpis, CustomerCount } from '@arrived/models';

interface ValuePropItemProps extends StackProps {
  value: ReactNode;
  label: ReactNode;
  ariaLabelId: string;
}

const ValuePropItem = ({ label, value, ariaLabelId, ...rest }: ValuePropItemProps) => (
  <Stack alignItems="center" color={({ palette }) => palette.neutrals.white} {...rest}>
    <Typography
      aria-labelledby={ariaLabelId}
      variant="h4.semibold"
      component="p"
      fontSize={({ fontSizes }) => ({
        xs: fontSizes['3xl'],
        md: fontSizes['6xl'],
      })}
    >
      {value}
    </Typography>
    <Typography
      id={ariaLabelId}
      variant="body1"
      fontSize={({ fontSizes }) => ({
        xs: fontSizes['lg'],
        md: fontSizes['2xl'],
      })}
      lineHeight={({ lineHeights }) => ({
        xs: lineHeights['xl'],
        md: lineHeights['3xl'],
      })}
      pt={1}
    >
      {label}
    </Typography>
  </Stack>
);

export interface ValuePropsProps extends StackProps {
  customerCount: CustomerCount;
  kpis: CompanyKpis;
}

export const ValueProps = ({ customerCount, kpis, sx, ...rest }: ValuePropsProps) => {
  const showHomepageBezosQuotes = useIsFeatureFlagEnabled(FEATURE_FLAGS.SHOW_HOMEPAGE_BEZOS_QUOTES);

  return (
    <Stack
      justifyContent="space-evenly"
      alignItems="center"
      direction={{
        xs: 'column',
        xl: 'row',
      }}
      minWidth="75%"
      gap={{ xs: 3, xl: 0 }}
      py={{ xs: 6, xl: 9 }}
      mt={showHomepageBezosQuotes ? { xs: 5, xl: 10 } : undefined}
      mx={{ xs: 1.25, xl: 6 }}
      borderRadius="10px"
      sx={{ background: ({ gradients }) => gradients.yin.vertical, ...sx }}
      data-e2e="homepage-v2.value-props"
      {...rest}
    >
      <ValuePropItem
        ariaLabelId="dividends-paid"
        value={
          <FormattedMessage
            id="homepage-v2.value-props.dividends-paid.value"
            values={{
              dividendsValue: (
                <FormattedNumber
                  value={kpis.totalDividendsPaid + kpis.totalInterestPaid}
                  style="currency"
                  currency="USD"
                  maximumFractionDigits={0}
                  notation="compact"
                  minimumSignificantDigits={2}
                  maximumSignificantDigits={2}
                />
              ),
            }}
          />
        }
        label={<FormattedMessage id="homepage-v2.value-props.dividends-paid.label" />}
      />
      <ValuePropItem
        ariaLabelId="registered-investors"
        value={<FormattedNumber value={customerCount.count} notation="compact" />}
        label={<FormattedMessage id="homepage-v2.value-props.registered-investors.label" />}
      />
      <ValuePropItem
        ariaLabelId="investor-owned-property-value"
        value={
          <FormattedNumber
            value={kpis.totalAum}
            style="currency"
            currency="USD"
            maximumFractionDigits={0}
            notation="compact"
          />
        }
        label={<FormattedMessage id="homepage-v2.value-props.total-invested" />}
      />
    </Stack>
  );
};

import { ReactNode } from 'react';

import { Stack, SxProps, Theme, Typography } from '@mui/material';

import { Numeral } from '@arrived/components';

interface ReturnCardProps {
  label: ReactNode;
  title: ReactNode;
  returnPercentage: number;
  sx?: SxProps<Theme>;
}
export const ReturnCard = (props: ReturnCardProps) => {
  const { label, title, returnPercentage, sx } = props;

  return (
    <Stack direction="row" justifyContent="space-between" sx={sx} pb={2.5}>
      <Stack direction="column" gap={1}>
        <Typography variant="caption" sx={{ textTransform: 'uppercase' }}>
          {label}
        </Typography>
        <Typography variant="h6">{title}</Typography>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        p={0.5}
        sx={{
          backgroundColor: ({ palette }) => (returnPercentage > 0 ? palette.mint[50] : palette.coral[50]),
        }}
      >
        <Typography variant="h6">
          {returnPercentage > 0 && '+'}
          <Numeral value={returnPercentage} showDecimal={true} isCompact={true} />%
        </Typography>
      </Stack>
    </Stack>
  );
};

import { useCallback } from 'react';

import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { yupResolver } from '@hookform/resolvers/yup';

import { Button, ButtonProps, Form, GetProps, styled, withStaticProperties } from '@arrived/bricks';
import { Input, InputProps } from '@arrived/bricks-form';
import { EmailListSubscriptionSchema } from '@arrived/forms';
import { EmailSubscribeMutationRequest } from '@arrived/queries';

const EmailInput = ({ errorMapping, ...rest }: Omit<InputProps, 'control' | 'name'>) => {
  const intl = useIntl();
  const { control } = useFormContext();

  return (
    <Input
      errorMapping={{
        'emailList.subscription.schema.error': intl.formatMessage({ id: 'emailList.subscription.schema.error' }),
        ...errorMapping,
      }}
      control={control}
      name="email"
      {...rest}
    />
  );
};

const EmailButton = ({ children, ...rest }: ButtonProps) => (
  <Form.Trigger asChild>
    <Button {...rest}>
      {children ?? <FormattedMessage id="landing-page.get-started" />}
    </Button>
  </Form.Trigger>
);

export type EmailSignupProps = Omit<GetProps<typeof Form>, 'onSubmit'> & { onSubmit: (email: string) => void };

export const EmailSignup = withStaticProperties(
  styled(Form).styleable<EmailSignupProps>(({ children, onSubmit, ...rest }) => {
    const form = useForm({ mode: 'onSubmit', resolver: yupResolver(EmailListSubscriptionSchema) });

    const handleSubmit = useCallback(
      form.handleSubmit((values: EmailSubscribeMutationRequest) => onSubmit(values.email)),
      [form.handleSubmit, onSubmit],
    );

    return (
      <FormProvider {...form}>
        <Form onSubmit={handleSubmit} {...rest}>
          {children}
        </Form>
      </FormProvider>
    );
  }),
  {
    Button: EmailButton,
    Input: EmailInput,
  },
);

import { FormattedMessage, FormattedNumber } from 'react-intl';

import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';

import {
  sfrLeveredReturnLower,
  sfrLeveredReturnUpper,
  sfrUnleveredReturnLower,
  sfrUnleveredReturnUpper,
  vrLeveredReturnLower,
  vrLeveredReturnUpper,
  vrUnleveredReturnLower,
  vrUnleveredReturnUpper,
} from '@arrived/common';

interface ReturnsRangeProps {
  lower: number;
  upper: number;
}

const ReturnRange = ({ lower, upper }: ReturnsRangeProps) => (
  <FormattedMessage
    id="common.return-range"
    values={{
      lower: <FormattedNumber value={lower} minimumFractionDigits={0} maximumFractionDigits={1} style="percent" />,
      upper: <FormattedNumber value={upper} minimumFractionDigits={0} maximumFractionDigits={1} style="percent" />,
    }}
  />
);

export const ExpectedReturnsTable = () => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell colSpan={3}>
            <Typography fontWeight="bold" textAlign="center">
              <FormattedMessage id="property-details.common-questions.expected-returns.table.header" />
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>&nbsp;</TableCell>
          <TableCell>
            <FormattedMessage id="common.single.family.residential" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="common.vacation.rental" />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>
            <FormattedMessage id="common.without-leverage" />
          </TableCell>
          <TableCell>
            <ReturnRange lower={sfrUnleveredReturnLower} upper={sfrUnleveredReturnUpper} />
          </TableCell>
          <TableCell>
            <ReturnRange lower={vrUnleveredReturnLower} upper={vrUnleveredReturnUpper} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <FormattedMessage id="common.with-leverage" />
          </TableCell>
          <TableCell>
            <ReturnRange lower={sfrLeveredReturnLower} upper={sfrLeveredReturnUpper} />
          </TableCell>
          <TableCell>
            <ReturnRange lower={vrLeveredReturnLower} upper={vrLeveredReturnUpper} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <FormattedMessage id="common.target-investment-period" />
          </TableCell>
          <TableCell>
            <FormattedMessage
              id="common.target-investment-period.range"
              values={{
                lower: 5,
                upper: 7,
              }}
            />
          </TableCell>
          <TableCell>
            <FormattedMessage
              id="common.target-investment-period.range"
              values={{
                lower: 5,
                upper: 15,
              }}
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

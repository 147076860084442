import { Constants } from '@arrived/common';

export interface InTheNewsConfigItem {
  png: {
    width: number;
    height: number;
    image: string;
  };
  svg: {
    width: number;
    height: number;
    image: string;
  };
  name: string;
  path?: string;
  quote?: string;
}

export const marketingInTheNewsConfig: InTheNewsConfigItem[] = [
  {
    png: {
      width: 121,
      height: 44,
      image: `${Constants.imageCdnUrl}/images/article_logos/yahoo-finance.png`,
    },
    svg: {
      width: 121,
      height: 44,
      image: `${Constants.awsCdnUrl}/images/landing/articles/yahoo-finance.svg`,
    },
    name: 'Yahoo Finance',
    path: 'https://www.yahoo.com/video/bezos-backed-real-estate-startup-103432712.html',
    quote: '“Jeff Bezos-Backed Real Estate Startup Surpasses $65 Million In Funded Property Value”',
  },
  {
    png: {
      width: 121,
      height: 23,
      image: `${Constants.imageCdnUrl}/images/article_logos/bloomberg.png`,
    },
    svg: {
      width: 121,
      height: 23,
      image: `${Constants.awsCdnUrl}/images/landing/articles/bloomberg.svg`,
    },
    name: 'Bloomberg',
    path: 'https://www.bloomberg.com/news/articles/2021-06-16/billionaires-back-startup-that-wants-to-let-anyone-be-a-landlord',
    quote:
      '“Jeff Bezos and Marc Benioff are backing a startup that lets regular people invest small amounts of money in single-family rental properties”',
  },
  {
    png: {
      width: 130,
      height: 40,
      image: `${Constants.imageCdnUrl}/images/article_logos/business-insider.png`,
    },
    svg: {
      width: 130,
      height: 40,
      image: `${Constants.awsCdnUrl}/images/landing/articles/business_insider.svg`,
    },
    name: 'Business Insider',
    path: 'https://www.businessinsider.com/real-estate-investing-strategy-passive-income-single-family-rental-properties-2021-11',
    quote:
      '"Jeff Bezos-backed real estate investment company breaks down how retail investors can acquire single-family rentals with as little as $100 - and shares why his firm\'s inventory of properties keeps selling out within 24 hours"',
  },
  {
    png: {
      width: 159,
      height: 24,
      image: `${Constants.imageCdnUrl}/images/article_logos/tech-crunch.png`,
    },
    svg: {
      width: 70,
      height: 40,
      image: `${Constants.awsCdnUrl}/images/landing/articles/tech_crunch.svg`,
    },
    name: 'TechCrunch',
    path: 'https://techcrunch.com/2022/05/17/backed-by-forerunner-and-bezos-back-arrived-a-startup-that-lets-you-buy-into-single-family-rentals-for-as-little-as-100/',
    quote: "\"Bezos backs Arrived, a startup that lets you buy into single-family rentals for 'as little as $100'\"",
  },
  {
    png: {
      width: 80,
      height: 44,
      image: `${Constants.imageCdnUrl}/images/article_logos/wsj-logo.svg`,
    },
    svg: {
      width: 80,
      height: 44,
      image: `${Constants.awsCdnUrl}/images/landing/articles/wsj.svg`,
    },
    name: 'Wall Street Journal',
    path: 'https://www.wsj.com/articles/everyones-a-landlordsmall-time-investors-snap-up-out-of-state-properties-11661705278',
    quote:
      '"Arrived, signed up 12,000 people to invest in 150 rental homes in the past year, with more than 100,000 others applying to make future investments through the company."',
  },
];

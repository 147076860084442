import { SyntheticEvent, useEffect, useState } from 'react';

import Image from 'next/image';

import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Stack, Tab, Typography } from '@mui/material';

import { FEATURE_FLAGS, useIsFeatureFlagEnabled } from '@arrived/feature-flags';

import { marketingInTheNewsConfig } from './config';

export const InThePress = () => {
  const [value, setValue] = useState('0');

  const handleChange = (_: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setValue((prev) => {
        if (prev === (marketingInTheNewsConfig.length - 1).toString()) {
          return '0';
        }

        return (parseInt(prev) + 1).toString();
      });
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const showHomepageBezosQuotes = useIsFeatureFlagEnabled(FEATURE_FLAGS.SHOW_HOMEPAGE_BEZOS_QUOTES);

  return (
    <Box
      sx={{
        '.news-item': {
          filter: 'grayscale(1) opacity(0.4)',

          '&:hover': {
            filter: 'none',
          },

          '&.Mui-selected': {
            filter: 'none',
          },
        },
      }}
      width="100%"
      data-e2e="homepage-v2.in-the-press"
    >
      <TabContext value={value}>
        <Stack direction="row" sx={{ position: 'relative', zIndex: ({ zIndex }) => zIndex.docked, mb: '-1px' }}>
          <TabList
            onChange={handleChange}
            variant="scrollable"
            scrollButtons={false}
            sx={{
              px: 4,
              flexGrow: 1,
              maxWidth: ({ breakpoints }) => `${breakpoints.values.lg}px`,
              mx: 'auto',

              '& .MuiTabs-flexContainer': {
                justifyContent: 'space-between',
              },

              '& .MuiTabs-indicator': {
                display: showHomepageBezosQuotes ? undefined : 'none',
                height: 0,

                '&::before': {
                  content: '""',
                  position: 'absolute',
                  bottom: 0,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  width: 0,
                  height: 0,
                  borderLeft: '14px solid transparent',
                  borderRight: '14px solid transparent',
                  borderBottom: ({ palette }) => `14px solid ${palette.neutrals.platinum}`,
                },

                '&::after': {
                  content: '""',
                  position: 'absolute',
                  bottom: '-1px',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  width: 0,
                  height: 0,
                  borderLeft: '13px solid transparent',
                  borderRight: '13px solid transparent',
                  borderBottom: ({ palette }) => `13px solid ${palette.neutrals.white}`,
                },
              },
            }}
          >
            {marketingInTheNewsConfig.map(({ png: { image, width, height }, name }, index) => (
              <Tab
                key={index}
                className="news-item"
                value={index.toString()}
                label={<Image width={width} height={height} src={image} alt={name} />}
                sx={{
                  py: {
                    xs: 4,
                    md: 5,
                  },
                  px: 2,
                }}
              ></Tab>
            ))}
          </TabList>
        </Stack>
        {showHomepageBezosQuotes && (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{
              position: 'relative',
              zIndex: ({ zIndex }) => zIndex.docked - 1,
              backgroundColor: ({ palette }) => palette.neutrals.white,
              minHeight: {
                xs: 195,
                lg: 185,
              },
              mx: {
                xs: 1.25,
                md: 6,
              },
              border: ({ palette }) => `1px solid ${palette.neutrals.platinum}`,
              borderRadius: '10px',
              boxShadow:
                '0px 1.1481481790542603px 3.1481480598449707px 0px rgba(0, 0, 0, 0.00), 0px 5.051851749420166px 6.518518447875977px 0px rgba(0, 0, 0, 0.01), 0px 12.399999618530273px 13px 0px rgba(0, 0, 0, 0.01), 0px 23.881481170654297px 25.481481552124023px 0px rgba(0, 0, 0, 0.01), 0px 40.185184478759766px 46.85185241699219px 0px rgba(0, 0, 0, 0.02), 0px 62px 80px 0px rgba(0, 0, 0, 0.02)',
            }}
            data-e2e="homepage-v2.in-the-press.bubble"
          >
            {marketingInTheNewsConfig.map(({ quote }, index) => (
              <TabPanel value={index.toString()} key={index}>
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    textAlign: 'center',
                    width: '100%',
                    maxWidth: 'md',
                    mx: 'auto',
                  }}
                >
                  <Typography
                    variant="body1"
                    fontSize={({ fontSizes }) => ({
                      xs: fontSizes['lg'],
                      md: fontSizes['2xl'],
                    })}
                    lineHeight={({ lineHeights }) => ({
                      xs: lineHeights['lg'],
                      md: lineHeights['2.5xl'],
                    })}
                  >
                    {quote}
                  </Typography>
                </Stack>
              </TabPanel>
            ))}
          </Stack>
        )}
      </TabContext>
    </Box>
  );
};

import { ReactNode } from 'react';

import { Box, Stack, StackProps, Typography } from '@mui/material';

export interface InformationSectionProps extends Omit<StackProps, 'title'> {
  tagline?: ReactNode;
  title: ReactNode;
  cta?: ReactNode;
  dataE2e?: string;
}

export const InformationSection = ({ tagline, title, cta, children, dataE2e, ...rest }: InformationSectionProps) => (
  <Stack
    className="information-section"
    direction={{ xs: 'column', md: 'row' }}
    justifyContent={{ md: 'center' }}
    gap={{ xs: 2, md: 4 }}
    flexWrap={{ xs: 'nowrap', md: 'wrap' }}
    mx={{ xs: 3.5, lg: 17 }}
    my={{ xs: 6, md: 14 }}
    data-e2e={dataE2e}
    {...rest}
  >
    <Stack direction="column" order={1} gap={2} flex={{ md: '1 1 500px' }}>
      {tagline && (
        <Stack position="relative" className="information-section-tagline">
          <Box
            height={14}
            width={14}
            sx={{
              position: 'absolute',
              top: '-14px',
              left: '-14px',
              background: ({ palette }) => palette.newPrimary[100],
              borderRadius: '3px',
              display: {
                xs: 'none',
                md: 'flex',
              },
            }}
          />
          <Typography
            variant="h6.medium"
            component="h5"
            sx={{
              background: ({ palette }) => palette.newPrimary[100],
              p: 0.5,
              borderRadius: '5px',
              maxWidth: 'max-content',
            }}
          >
            {tagline}
          </Typography>
        </Stack>
      )}
      <Typography variant="h4.medium" component="h3">
        {title}
      </Typography>
    </Stack>
    <Box order={{ xs: 2, md: 3 }} width="100%" position="relative">
      {children}
    </Box>
    {cta && (
      <Stack
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          flex: { xs: '1 1 auto', md: '0 1 auto' },
          order: { xs: 3, md: 2 },
          ml: { md: 'auto' },
        }}
      >
        {cta}
      </Stack>
    )}
  </Stack>
);

import React from 'react';

import { FormattedMessage } from 'react-intl';

import { Stack, Typography } from '@mui/material';

import { InformationSection } from './InformationSection';
import { ReturnCard } from './ReturnCard';

const RETURN_CARDS_CONFIG = [
  {
    label: <FormattedMessage id="homepage-v2.return-card.arrived.label" />,
    title: <FormattedMessage id="homepage-v2.return-card.arrived.title" />,
    returnPercentage: 24.8,
  },
  {
    label: <FormattedMessage id="homepage-v2.return-card.public-reits.label" />,
    title: <FormattedMessage id="homepage-v2.return-card.public-reits.title" />,
    returnPercentage: -19.8,
  },
  {
    label: <FormattedMessage id="homepage-v2.return-card.public-stocks.label" />,
    title: <FormattedMessage id="homepage-v2.return-card.public-stocks.title" />,
    returnPercentage: -7.7,
  },
];

export const ReturnCards = () => {
  return (
    <InformationSection
      tagline={<FormattedMessage id="homepage-v2.return-cards.tagline" />}
      title={<FormattedMessage id="homepage-v2.return-cards.title" />}
      dataE2e="homepage-v2.return-cards"
      // twoColChildren
    >
      <Stack
        gap={1}
        sx={{
          width: '100%',
          maxWidth: {
            xs: '500px',
            lg: '590px',
          },
          mx: 'auto',
        }}
      >
        <Stack
          sx={{
            width: '100%',
            border: ({ palette }) => `1px solid ${palette.neutrals.platinum}`,
            borderRadius: '10px',
            background: ({ palette }) => palette.neutrals.white,
            gap: {
              xs: 3,
              md: 4,
            },
            p: {
              xs: 3,
              md: 6,
            },
            pb: {
              xs: 2,
            },
          }}
        >
          {RETURN_CARDS_CONFIG.map(({ label, title, returnPercentage }, index, array) => (
            <ReturnCard
              label={label}
              title={title}
              returnPercentage={returnPercentage}
              sx={({ palette }) => ({
                borderBottom: index < array.length - 1 ? `1px solid ${palette.extras.fog}` : 0,
              })}
              key={index}
            />
          ))}
        </Stack>

        <Typography variant="body2" color={({ palette }) => palette.darkened.seal} textAlign="right" mb={1}>
          <FormattedMessage id="homepage-v2.return-cards.caption" />
        </Typography>
      </Stack>
    </InformationSection>
  );
};

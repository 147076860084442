import React, { ReactNode, SyntheticEvent, useCallback, useState } from 'react';

import { FormattedMessage } from 'react-intl';

import ExpandMore from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material';

import { CONFIG } from '@arrived/config';

import { NextLink } from '../Link';

import { ExpectedReturnsTable } from './ExpectedReturnsTable';

interface Faq {
  question: ReactNode;
  answer: ReactNode;
}

const faqs: Faq[] = [
  {
    question: <FormattedMessage id="homepage-v2.faqs.who-can-invest-question" />,
    answer: (
      <Typography>
        <FormattedMessage id="homepage-v2.faqs.who-can-invest-answer" values={{ br: <br /> }} />
      </Typography>
    ),
  },
  {
    question: <FormattedMessage id="homepage-v2.faqs.what-properties-question" />,
    answer: (
      <Typography>
        <FormattedMessage
          id="homepage-v2.faqs.what-properties-answer"
          values={{ br: <br />, strong: (parts: ReactNode[]) => <strong>{parts}</strong> }}
        />
      </Typography>
    ),
  },
  {
    question: <FormattedMessage id="homepage-v2.faqs.what-returns-question" />,
    answer: (
      <Stack gap={2}>
        <Typography>
          <FormattedMessage
            id="property-details.common-questions.expected-returns.description.part-1"
            values={{
              br: <br />,
              strong: (parts: ReactNode[]) => <strong>{parts}</strong>,
            }}
          />
        </Typography>
        <ExpectedReturnsTable />
        <Typography>
          <FormattedMessage id="property-details.common-questions.expected-returns.description.part-2" />
        </Typography>
        <NextLink href={`${CONFIG.helpUrl}articles/4953922-what-returns-can-i-expect-from-arrived-properties`}>
          <Typography>
            <FormattedMessage id="common.learn-more" />
          </Typography>
        </NextLink>
      </Stack>
    ),
  },
];

export const Faqs = () => {
  const [expanded, setExpanded] = useState<number>();

  const handleOnExpand = useCallback(
    (idx: number) => (_: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? idx : undefined);
    },
    [],
  );
  return (
    <Stack sx={{ alignItems: 'center' }} data-e2e="homepage-v2.faqs">
      <Stack sx={{ mx: { xs: 3.5, md: 0 }, maxWidth: { md: '840px' }, gap: { xs: 2, md: 3.5 } }}>
        {faqs.map(({ question, answer }, index) => (
          <Accordion
            key={index}
            disableGutters
            expanded={expanded === index}
            onChange={handleOnExpand(index)}
            TransitionProps={{ timeout: 1000 }}
            sx={{
              px: 1,
              backgroundColor: 'transparent',
              border: ({ palette }) => `1px solid ${palette.neutrals.platinum}`,
              '&:before': {
                display: 'none',
              },
              '&, &:first-of-type, &:last-of-type': {
                borderRadius: '10px',
              },
            }}
          >
            <AccordionSummary expandIcon={<ExpandMore fontSize="medium" />}>
              <Typography variant="h5">{question}</Typography>
            </AccordionSummary>
            <AccordionDetails>{answer}</AccordionDetails>
          </Accordion>
        ))}
      </Stack>
    </Stack>
  );
};

export default function nextImageKitLoader({ src, width, quality }) {
  const newImgUrl = src.replace('https://cdn.arrivedhomes.com', 'https://images.arrived.com');
  const parameters = ['f-auto', 'dpr-auto', 'pr-true', 'di-logo_icon_CwvvbRzw9.svg', 'c-maintain_ratio'];

  if (width != null) {
    parameters.push(`w-${width}`);
  }

  if (quality && quality > 0 && quality <= 100) {
    parameters.push(`q-${quality}`);
  }

  const queryParams = parameters.length > 0 ? `?tr=${parameters.filter(Boolean).join(',')}` : '';

  return `${newImgUrl}${queryParams}`;
}

import { GetProps, VariantSpreadFunction, createStyledContext, styled, useProps } from '@tamagui/core';

import { UtilityText } from '../../text';
import { PillContainer } from '../Pill';

const FILTER_CONTAINER_NAME = 'FilterContainer';
const FILTER_LABEL_NAME = 'FilterLabel';

interface FilterStyledContextType {
  active?: boolean;
  disabled?: boolean;
  colors?: 'primary' | 'negative';
}

const FilterStyledContext = createStyledContext<FilterStyledContextType>({
  active: false,
  disabled: false,
  colors: 'primary',
});

const getFilterActiveProps: VariantSpreadFunction<
  GetProps<typeof PillContainer> & FilterStyledContextType,
  boolean | undefined
> = (active, { props: { colors } }) => {
  if (active) {
    switch (colors) {
      case 'negative':
        return {
          bg: '$surface.negative.defaultAlt',
          borderColor: '$interactive.negative.rested',
        };
      case 'primary':
      default:
        return {
          bg: '$surface.primary.default',
          borderColor: '$interactive.primary.rested',
        };
    }
  } else {
    return {};
  }
};

const FilterContainer = styled(PillContainer, {
  name: FILTER_CONTAINER_NAME,
  context: FilterStyledContext,
  tag: 'button',

  bg: '$surface.neutral.default',
  borderWidth: 1,
  borderColor: 'transparent',
  cursor: 'pointer',

  // @ts-expect-error the tamagui types here need updating
  group: 'filter',
  // group defaults to making container-type 'inline-size' which forces the component to have a defined width, in this case we want to
  // size to the contents so we change container-type to 'normal'
  style: {
    containerType: 'normal',
  },

  hoverStyle: {
    borderColor: '$interactive.primary.hovered',
  },
  focusStyle: {
    borderColor: '$interactive.primary.focus',
    outlineWidth: 0,
  },

  variants: {
    active: getFilterActiveProps,
    disabled: {
      false: {},
      true: {
        bg: '$surface.neutral.default',
        focusable: false,
        cursor: 'auto',
        hoverStyle: {
          borderColor: 'transparent',
        },
        focusStyle: {
          borderColor: 'transparent',
          outlineWidth: 0,
        },
      },
    },
    colors: {
      primary: {},
      negative: {
        hoverStyle: {
          borderColor: '$interactive.negative.hovered',
        },
        focusStyle: {
          borderColor: '$interactive.negative.rested',
          outlineWidth: 0,
        },
      },
    },
  } as const,
});

const FilterLabel = styled(UtilityText, {
  name: FILTER_LABEL_NAME,
  context: FilterStyledContext,

  token: 'utility.helper.medium',
  color: '$onSurface.primary.default',

  // @ts-expect-error the tamagui types here need updating
  '$group-filter-hover': {
    color: '$interactive.primary.hovered',
  },

  variants: {
    disabled: {
      false: {},
      true: {
        color: '$interactive.neutral.disabledInverted',
        '$group-filter-hover': {
          color: '$interactive.neutral.disabledInverted',
        },
      },
    },
    colors: {
      primary: {},
      negative: {
        color: '$onSurface.negative.default',
        '$group-filter-hover': {
          color: '$interactive.negative.hovered',
        },
      },
    },
  } as const,
});

export const Filter = FilterContainer.styleable<
  GetProps<typeof FilterContainer> & { labelProps?: GetProps<typeof FilterLabel> }
>((propsIn, ref) => {
  const { active, children, disabled, labelProps, ...rest } = useProps(propsIn);

  return (
    <FilterContainer active={active && !disabled} disabled={disabled} ref={ref} {...rest}>
      <FilterLabel {...labelProps}>{children}</FilterLabel>
    </FilterContainer>
  );
});

export type FilterProps = GetProps<typeof Filter>;

import * as React from 'react';

import Image from 'next/image';

import { Stack } from '@mui/material';

import { Constants } from '@arrived/common';

interface TopBenefitsImageProps {
  imageHeight: number;
  imageUrl: string;
  imageWidth: number;
}

export const TopBenefitsImage = ({ imageHeight, imageUrl, imageWidth }: TopBenefitsImageProps) => {
  return (
    <Stack
      sx={{
        position: 'relative',
        flex: { xs: '0 0 160px', md: '0 0 210px' },
        justifyContent: 'center',
        alignItems: 'center',
        width: { xs: '260px', md: '210px' },
        height: { xs: '160px', md: '210px' },
        background: ({ gradients }) => gradients.newLight.horizontal,
        borderRadius: '10px',
      }}
    >
      <Image alt="" src={`${Constants.awsCdnLandingUrl}/${imageUrl}`} width={imageWidth} height={imageHeight} />
    </Stack>
  );
};

import { FormattedMessage } from 'react-intl';

import { Avatar, Stack, StackProps, Typography } from '@mui/material';

import { getCDNOptimizedImage } from '@arrived/common';

const cameronImage = getCDNOptimizedImage({
  imgUrl: 'https://cdn.arrivedhomes.com/images/cameron-2.png',
  width: '200px',
});

export const CameronQuote = (props: StackProps) => (
  <Stack
    alignItems="center"
    justifyContent="center"
    direction={{ xs: 'column-reverse', lg: 'row' }}
    gap={2}
    mt={3}
    {...props}
  >
    <Stack
      alignItems="center"
      direction="row"
      gap={{
        xs: 2,
        md: 3,
      }}
    >
      <Avatar
        alt="Cameron Wu"
        src={cameronImage}
        sx={{
          backgroundColor: ({ palette }) => palette.neutrals.white,
          height: {
            xs: '82px',
            md: '150px',
          },
          width: {
            xs: '82px',
            md: '150px',
          },
          border: ({ palette }) => `1px solid ${palette.neutrals.platinum}`,
        }}
      />
      <Stack direction="column" alignItems="flex-start" gap={3}>
        <Typography display="flex" maxWidth={{ xs: '500px', lg: '385px' }}>
          <FormattedMessage id="homepage-v2.diligence-process.quote" />
        </Typography>
        <Typography>
          <FormattedMessage
            id="cameron-quote.name-title"
            values={{
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              strong: (parts) => <strong>{parts}</strong>,
            }}
          />
        </Typography>
      </Stack>
    </Stack>
  </Stack>
);

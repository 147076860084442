import { useState } from 'react';

import { FormattedMessage } from 'react-intl';

import CloseIcon from '@mui/icons-material/Close';
import PlayCircle from '@mui/icons-material/PlayCircle';
import { Box, Button, Dialog, DialogTitle, IconButton } from '@mui/material';

import { useTheme } from '@arrived/bricks';
import { Constants } from '@arrived/common';
import { SlideUp } from '@arrived/components';

export const MarketingVideo = () => {
  const theme = useTheme();
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  const handleVideoOnClick = () => {
    setIsVideoModalOpen(true);
  };

  const handleVideoOnClose = () => {
    setIsVideoModalOpen(false);
  };

  return (
    <>
      <Button
        variant="text"
        size="medium"
        onClick={handleVideoOnClick}
        startIcon={<PlayCircle sx={{ color: theme['interactive.primary.rested'].variable }} />}
        sx={{
          pr: 0,
          pl: 1,
          justifyContent: 'flex-start',
        }}
      >
        <Box
          sx={{
            background: theme['surface.primary.default'].variable,
          }}
        >
          <FormattedMessage id="what-is-arrived" defaultMessage="What's Arrived?" />
        </Box>
      </Button>
      <Dialog
        maxWidth={false}
        fullWidth
        onClose={handleVideoOnClose}
        open={isVideoModalOpen}
        TransitionComponent={SlideUp}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          {handleVideoOnClose ? (
            <IconButton
              aria-label="close"
              onClick={handleVideoOnClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <video style={{ height: '85vh' }} autoPlay controls>
          <source src={Constants.videoLink} type="video/mp4" />
        </video>
      </Dialog>
    </>
  );
};
